<template>
  <div class="reject-project-booking">
    <div class="card">
      <fd-form @submit="submitForm">
        <modal-body class="p-3">
          <h3 class="mb-4">Reject Booking</h3>
          <p class="mb-4">
            Please write a reason for the rejection of this booking.
          </p>

          <div class="row">
            <fd-textarea
              v-model="form.description"
              class="col-12 mb-2"
              label="Description"
              name="rejectReasonDesc"
              type="text"
              :validators="[validator.required]"
            >
            </fd-textarea>
          </div>
        </modal-body>

        <modal-footer class="row justify-content-end p-2">
          <button type="button" class="btn mr-1" @click="$emit('cancel')">
            Cancel
          </button>
          <button class="btn main">Reject</button>
        </modal-footer>
      </fd-form>
    </div>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import ProjectBooking from "@/models/projectBooking";

export default {
  components: {},
  mixins: [],
  props: {
    bookingId: {
      type: [Number, String],
      required: true
    }
  },
  data: function () {
    return {
      form: {
        description: ""
      },
      validator: {
        required: required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    submitForm() {
      this.$emit(
        "submit",
        this.bookingId,
        ProjectBooking.postCancelRejectPayload(this.form)
      );
    }
  }
};
</script>

<style lang="scss">
.reject-project-booking {
  min-width: 500px;
}
</style>
